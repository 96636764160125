import React from 'react'
import PropTypes from 'prop-types'

import DownloadPage from '../../components/DownloadPage'
import { getParamFromURL } from '../../helpers'
import SEO from '../../components/SEO'

const DownloadInfo = ({ location }) => {
  const id = getParamFromURL(location.search, 'id')

  return (
    <>
      <SEO page="download" />
      <DownloadPage id={id} />
    </>
  )
}

DownloadInfo.propTypes = {
  location: PropTypes.object.isRequired,
}

export default DownloadInfo
