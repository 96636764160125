import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import Wrapper from '../Wrapper'

import { DOWNLOAD_INFO } from '../../data/apis'
import { getData } from '../../reducers/app'
import Adsense from '../Adsense'
import { useDetectAdBlock } from '../../hooks/useAdblock'
import AdblockImage from '../../static/adblock-detect.png'

const DownloadInfo = ({ id }) => {
  const [isLoading, setLoading] = useState(true)
  const adblockDetectStatus = useDetectAdBlock()
  const dispatch = useDispatch()
  const {
    downloadinfo: { loaded, data, error },
  } = useSelector(state => state.app)

  useEffect(() => {
    if (id && adblockDetectStatus === 'success')
      dispatch(getData(DOWNLOAD_INFO(id))).finally(() => setLoading(false))
    if (!id) setLoading(false)
  }, [id, adblockDetectStatus])

  const handleReload = () => {
    window.location.reload()
  }

  return (
    <>
      <Wrapper title="Your Download Info" showAds>
        <div className="col-sm-6">
          <div className="cboxEraForm text-center mt-4">
            {!isLoading && !id && (
              <div>
                <i className="fas fa-unlink fa-3x text-danger"></i>
                <p className="pt-4 mb-1 font-weight-bold">Oops..!</p>
                <p className="font-weight-bold">Invalid Download link</p>
              </div>
            )}
            {isLoading && adblockDetectStatus === 'pending' && (
              <span>
                <i
                  className="fas fa-cog fa-4x fa-spin mb-2 text-primary"
                  aria-hidden="true"
                />
                <p className="mt-2">Generating your link.</p>
                <p className="mt-2">Please wait....</p>
              </span>
            )}
            {adblockDetectStatus === 'rejected' && (
              <div className="cboxEraForm text-center mt-0 mb-0">
                {/* <i className="far fa-frown-open text-danger fa-3x"></i> */}
                <img
                  src={AdblockImage}
                  alt="Adblocker"
                  height="150"
                  width="150"
                  className="mb-4"
                />
                {/* <p className="mt-3 mb-1 font-weight-bold">Adblock Detected</p> */}
                <p className="text-danger font-weight-bold">
                  Please disable your Adblock to download this link.
                </p>
                <Button
                  className="mt-2"
                  color="success"
                  size="md"
                  onClick={handleReload}
                >
                  I disabled my ad blocker
                </Button>
              </div>
            )}
            {/*eslint-disable */}
            {loaded && data && data.dlink && !error && (
              <span>
                {data.qr ? (
                  <img src={data.qr} alt="fas fa-file" title={data.fileName} />
                ) : (
                  <i
                    className="fas fa-file fa-4x mb-2 text-danger"
                    aria-hidden="true"
                  />
                )}
                <p className="mt-2 mb-1 font-weight-bold text-dark">
                  File: {data.fileName}
                </p>
                <p className="mt-2 mb-1 font-weight-bold text-danger">
                  Size: {data.filesize}
                </p>
                <a rel="noopener noreferrer" href={data.dlink}>
                  <Button className="mt-2" color="success" size="md">
                    <i className="fa fa-download" /> Download
                  </Button>
                </a>
                <Adsense slot="6222161092" format="responsive" />
                <div className="mt-4 float-left font-italic text-danger">
                  * You can also scan QR Code above for downloading this link on
                  any other device.
                </div>
              </span>
            )}
            {error && loaded && (
              <span>
                <i
                  className="fas fa-exclamation-circle fa-4x mb-2 text-danger"
                  aria-hidden="true"
                />
                <p className="mt-3 mb-1 font-weight-bold">Oops..!</p>
                <p>{error}</p>
                <Adsense slot="6222161092" format="responsive" />
              </span>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  )
}

DownloadInfo.defaultProps = {
  id: '',
}

DownloadInfo.propTypes = {
  id: PropTypes.string,
}

export default DownloadInfo
