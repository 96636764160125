import { useEffect, useState } from 'react'
import fetch from 'node-fetch'

export const useDetectAdBlock = () => {
  const [adblockStatus, setadblockStatus] = useState('pending')

  useEffect(() => {
    const url = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
    fetch(url, {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-store',
    })
      .then(() => {
        setadblockStatus('success')
      })
      .catch(() => {
        console.log('Adblock Detected')
        setadblockStatus('rejected')
      })
  }, [])

  return adblockStatus
}
